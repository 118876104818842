<template>
  <div id="lastMovie">        
    <p v-for="lastmovie in json" v-bind:key=lastmovie.titre>
      <b>Dernière séance : </b>
      <span v-if="lastmovie.link"><a :href="lastmovie.link" target="_new">{{ lastmovie.titre }}</a> en {{ getSupport(lastmovie.support) }}</span>
      <span v-else>{{ lastmovie.titre }} en {{ getSupport(lastmovie.support) }}</span>
      <span v-if="lastmovie.note !== null">&nbsp;{{ getNote(lastmovie.note) }}</span>
    </p>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/membre/lastmovie/' + this.proprio)
    .then(response => (this.json = response.data.lastmovie))
  },    
  methods: {
    getSupport(idSupp) {
      if(idSupp == "hddvd") {
        idSupp = "hd-dvd"
      }
      else if(idSupp == "bd3d") {
        idSupp = "bd 3d"
      }
      else if(idSupp == "bd4k") {
        idSupp = "bd 4k"
      }
      return idSupp.toUpperCase();
    },
    getNote(value) {
      let i = 0
      let note = ''
      while( i <  value) {
        note = note + '⭐'
        i++
      }
      return note
    }
  } 
}
</script>

<style scoped>

p {
  margin-left:0px;
  margin-bottom:5px;
  font-size:1rem;
}

a:link {
  text-decoration: none;
}
</style>