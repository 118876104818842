<template>
  <div class="members container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Les membres du site Install-HC"
      description="Page regroupant tous les membres enregistrés sur le site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>    
    <div class="listeMembres">
      <h1>Les membres inscrits sur le site</h1>
      <div class="filtre">
        Que les membres disposant d'une installation
        <a href="#" class="menu" v-on:click="applyFilter('yes')">Oui</a>
        <a class="menu" href="#" v-on:click="applyFilter('no')">Non</a>
      </div>
      <div class="filtre">
        <label for="searchMember">Pseudo commençant par&nbsp;</label> 
        <input v-model="inputMemberName" type="text" id="searchMember" name="searchMember" minlength="1" maxlength="8" size="8">
      </div>
      <div class="clearfix"></div>                  
      <div v-bind:key=members.login v-for="members in json" class="membre d-inline-block">
        <div :id="'pos' + members.pos"  class="details" @click="getInfo($event)"> 
          <h2>{{ members.login }}&nbsp;<span v-if="members.install =='oui'" class="equiped">🔈</span></h2>
        </div>
        <ProprioDesc class="shadow infoMembre" v-if="isDisplayed('pos' + members.pos)" v-bind:membre="true" :proprio="members.login.replace(/ /g,'___')" @kill="kill"/>                      
      </div>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import ProprioDesc from '@/components/Presentation/Proprio.vue'
export default { 
  name: 'members',
  components: {
    Menu,
    InstallHC,
    ProprioDesc
  },
  data() {         
    return {
      json: null, 
      firstJson: null,
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      inputMemberName: '',
      clicked: null
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
    inputMemberName: function(text) {
      //alert(text)
      if(this.firstJson != null) {
        this.json = this.firstJson
      } 
      else if(this.firstJson === null) {
        this.firstJson = this.json
      }
      this.json = this.json.filter(function(m) {
        return m.login.toLowerCase().startsWith(text.toLowerCase())            
      })       
    }
  },
  mounted() {  
    window.addEventListener('scroll', this.updateScroll);
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/membre/liste')
    .then(response => (this.json = response.data.members))
  },
  methods: {
    getInfo: function(event ) {
      this.clicked = event.currentTarget.id
    },
    isDisplayed: function(actualPos) {
      if(actualPos == this.clicked) {
        return true 
      } else {
        return false
      }
    },
    kill: function() {
      this.clicked = null
    },
    applyFilter: function(choice) {
      if(this.firstJson === null) {
        this.firstJson = this.json
      }
      if (choice === 'no') {        
        this.json = this.firstJson        
      } else {            
        this.json = this.json.filter(function(m) {
          return m.install === "oui"            
        })                
      }
    }    
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.members {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

h2 {
  float:right;
  font-size:1.2em;
  margin: 4px 0;
}

a {
  font-weight: bold;  
  text-decoration: none;
}

.membre {
  text-align:left;
  padding:2px 4px;
  margin:2px;
  width:260px;
  font-size:0.9rem;  
  border-radius:4px;
  background-image: linear-gradient(135deg, #fff, #555);
  color:white;    
}

.details {
  display:inline-block;
  cursor: pointer;
  width:100%;
  vertical-align: middle;
}

.infoMembre {    
  background-color:#fff;
  color: #111;
  border-radius: 3px;
  border:1px solid #111;
  padding:2px; 
  position:absolute;
  z-index: 0;  
  width:230px;
}

.menu {
  display:inline-block;
  height:20px;
  line-height: 20px;
  width:inherit;
  height:inherit;
  border-radius: 4px;
  background: #ddd;
  padding:3px;
  margin:3px;
}

.filtre {
  display:inline-block;
  margin-right:10px;
}
</style>