<template>
  <div id="top">        
    <p v-for="top in json" v-bind:key=top.titre>
      <b>Top Demo {{ top.type.toUpperCase() }} : </b>
      <span v-if="top.link"><a :href="top.link" target="_new">{{ top.titre }}</a> en {{ getSupport(top.support) }}</span>
      <span v-else>{{ top.titre }} en {{ getSupport(lastmovie.support) }}</span>
    </p>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/membre/top/' + this.proprio)
    .then(response => (this.json = response.data.top))
  },    
  methods: {
    getSupport(idSupp) {
      if(idSupp == "hddvd") {
        idSupp = "hd-dvd"
      }
      else if(idSupp == "bd3d") {
        idSupp = "bd 3d"
      }
      else if(idSupp == "bd4k") {
        idSupp = "bd 4k"
      }
      return idSupp.toUpperCase();
    }
  } 
}
</script>

<style scoped>

p {
  margin-left:0px;
  margin-bottom:5px;
  font-size:1rem;
}

a:link {
  text-decoration: none;
}
</style>