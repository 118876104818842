<template>
    <div id="proprio" class="whitebg shadow">
        <div v-for="member in json" v-bind:key=member.login>
            <h2 @click="hideMe" v-if="membre" style="cursor:pointer">Le membre</h2>
            <h2 v-else>Le propriétaire</h2>
            <p>{{ member.phrase }}</p>
            <div style="margin-left:20px;">
              <LastMovie :proprio="proprio"/>
              <Top :proprio="proprio"/>
            </div>
            <p v-if="membre">
              <span v-if="member.install==='oui'"><router-link :to="{path: 'presentation/' + proprio }">🔈&nbsp;L'Installation HC de {{ member.login }}</router-link></span>
              <span v-else>🔈&nbsp;Pas d'Installation HC enregistrée</span>
            </p>
            <p v-if="member.collection"><a :href="member.collection" target="_blank">🖼️&nbsp;Consulter sa collection</a></p>
            <!-- <p><a href="#">✉️&nbsp;Envoyer un message à  <b>{{ member.login }}</b></a></p> -->
            <p>Membre depuis le {{ member.time_id }}</p>              
            <p>Dernière connexion le {{ member.lastlogin }}</p>              
        </div>      
    </div>
</template>

<script>
import axios from 'axios'
import Top from '@/components/Presentation/Top.vue'
import LastMovie from '@/components/Presentation/LastMovie.vue'
export default {
  name: 'proprio',
  components: {
    Top,
    LastMovie
  },
  props: {
    proprio: String,
    membre: Boolean
  },    
  data() {         
    return {
      json: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/membre/proprio/' + this.proprio)
    .then(response => (this.json = response.data.member))
  },
  methods: {
    hideMe: function(){
      this.$emit("kill")
    }
  }     
}
</script>

<style scoped>
#proprio {
    font-weight: normal;
    text-align:left;
    padding:10px;
    margin:10px;
    font-size:15px;
    border:1px solid #eee;
    border-radius: 5px;
}

h2 {
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAREVMAqxulQIAAABFElEQVQ4y83OvyvEcRzH8cf361co0aVTlEwWDMpmZbhZShn9ATfZrmyyGShFCUWx2CmbKIvkuzBQnB9FncHgx7Fcl8h9v5O8pvfr83m9ej35dwpQa1R7guy1TS+B0K68kwSFPmnD9DpIzHOoN5RyU35o1KO+QiEvFX6xgyILIv2VRqq/3LPG7MuYMQSazasp/b3Juv9eaBUhki75J0uqSnfR48+FNRvWTVgph/YqI+WMGzBnq+Rjkeo8OFdQ5T0JUqcdkWNZU0bk45EWTVsGWUsycUhpXba1gFWTGjxXRmpSdFReK2j2/BtSUYgz3eIVKtLmQipBmJRL6Wq3pp269hETD7TLuQtAjQ5BTOHDlddEJH+tTxK4T7pDigX6AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTE3VDIxOjQ4OjEwKzAwOjAw8n37XAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0xN1QyMTo0ODoxMCswMDowMIMgQ+AAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat;
  background-size:20px;
  padding-left:25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

a:link {
  text-decoration: none;
}
</style>